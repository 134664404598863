import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/ContactForm"
import { Button, Modal } from "antd"
import ImageGallery from "../components/ImageGallery"


const Perles_pierres = () => {
  //Hooks
  const [dataContact, setDataContact] = useState(false)

//Modal
  const [showModalContact, setShowModalContact] = useState(false)

  const showModalContactForm = (value_i) => {
    setShowModalContact(true)
    setDataContact(value_i)
  }

  const handleContactFormOk = e => {
    setShowModalContact(false)
  }

  const handleContactFormCancel = e => {
    setShowModalContact(false)
  }

  return (
    <>
  <Layout>
    <SEO title="Perles & Pierres" />

    <div className="slideshow">
      <div className="usp">
        <div className="usp-txt-header">Perles & Pierres</div>
      </div>
    </div>
    <div className="border-gold-bottom"></div>


    <h2 className="h2-mobile"  style={{ marginTop: 40 }}>Perles & Pierres</h2>


    <h3 className="h3-mobile" style={{textAlign: 'center'}}>Les différentes couleurs de Perles & de Pierres  donnent de la vie à vos bijoux.</h3>

    <p className="p-2 p-font mobile-display" style={{textAlign: 'center'}}>Les différentes couleurs de Perles & de Pierres donnent de la vie à vos bijoux.</p>
    <section>
      <ImageGallery id="perlespierres" />

      <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Perles & Pierres') }>Nous contacter</Button></div>

    </section>

  <Modal title="Formulaire de contact"
        style={{minWidth: '40vw', height: '80vh'}}
    visible={ showModalContact }
    onOk={ handleContactFormOk }
    onCancel={ handleContactFormCancel }
    okButtonProps={{ style: { display: 'none' } }}
  >

    <ContactForm page={dataContact} />

  </Modal>

    </Layout>
    </>
  )
}

export default Perles_pierres
